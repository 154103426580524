/*
 * Personalization Base Js
 */
;(function (window, document) {
  let AMPPersonalization = {}
  AMPPersonalization.hostName = null
  AMPPersonalization.pathName = null
  AMPPersonalization.pageUrl = null
  AMPPersonalization.applyNow = 'Log In and Apply'
  AMPPersonalization.defaultStatus = 'unrecognized'
  AMPPersonalization.clientExpired = 'expired'
  AMPPersonalization.getParameterByName = (
    name,
    url = window.location.href
  ) => {
    name = name.replace(/[\[\]]/g, '\\$&')
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    let results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }
  AMPPersonalization.addQueryParam = (uri, key, value) => {
    let response = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
    let separator = uri.indexOf('?') !== -1 ? '&' : '?'
    if (uri.match(response)) {
      return uri.replace(response, '$1' + key + '=' + value + '$2')
    } else {
      return uri + separator + key + '=' + value
    }
  }
  AMPPersonalization.userStatus = () => {
    return (
      document.cookie.replace(
        /(?:(?:^|.*;\s*)lastAmpAuthState\s*=\s*([^;]*).*$)|^.*$/,
        '$1'
      ) || AMPPersonalization.defaultStatus
    )
  }
  AMPPersonalization.clientData = [
    {
      pagename: 'credit-cards',
      timestamp: false,
      hasVisited: false,
      hasButtonClicked: false
    },
    {
      pagename: 'visa-signature',
      timestamp: false,
      hasVisited: false,
      hasButtonClicked: false
    },
    {
      pagename: 'premier-visa-signature',
      timestamp: false,
      hasVisited: false,
      hasButtonClicked: false
    },
    {
      pagename: 'ameriprise-rewards',
      timestamp: false,
      hasVisited: false,
      hasButtonClicked: false
    },
    {
      pagename: 'logout',
      timestamp: false,
      hasVisited: false,
      hasButtonClicked: false
    }
  ]

  AMPPersonalization.findCurrentUrl = () => {
    if (AMPPersonalization.pathName) {
      const pageUrlLength = AMPPersonalization.pathName.split('/')
      if (pageUrlLength && pageUrlLength.length > 0) {
        const filteredPageLen = pageUrlLength[pageUrlLength.length - 1]
        if (filteredPageLen) {
          AMPPersonalization.pageUrl = filteredPageLen
        }
      }
    }
    return AMPPersonalization.pageUrl
  }
  AMPPersonalization.creditCardsV2 = () => {
    if (
      !window.AMPCARDSPERSONALIZEDV2 ||
      window.AMPCARDSPERSONALIZEDV2 === 'false'
    ) {
      return false
    }

    if (
      AMPPersonalization.userStatus() === AMPPersonalization.defaultStatus ||
      AMPPersonalization.userStatus() === AMPPersonalization.clientExpired
    ) {
      return false
    }

    const isClientAppliedCC = () => {
      let clientJourneys = JSON.parse(
        window.localStorage.getItem('clientPageVisitInfo')
      )
      if (clientJourneys) {
        let returnResult = clientJourneys.filter(
          items =>
            items.pagename == 'credit-cards' && items.hasButtonClicked === true
        )
        if (returnResult && returnResult.length && returnResult.length > 0) {
          return true
        } else {
          return false
        }
      }
      return false
    }

    const enableV2LinksForCards = () => {
      let applyNowButtons = document.querySelectorAll('.link-btn')
      if (applyNowButtons && applyNowButtons.length) {
        applyNowButtons.forEach(item => {
          let cardProductCode = AMPPersonalization.getParameterByName(
            'productCode',
            item.href
          )
          if (cardProductCode) {
            if (cardProductCode === '00200') {
              // Premier
              let pCode =
                window.PERSONALIZATION.promoCode.premierCard || 'bauss'
              let itemHrefOne = AMPPersonalization.addQueryParam(
                item.href,
                'promoCode',
                pCode
              )
              item.href = itemHrefOne
            } else if (cardProductCode === '00100') {
              // Signature
              let pCode = window.PERSONALIZATION.promoCode.visaCard || 'bauss'
              let itemHrefTwo = AMPPersonalization.addQueryParam(
                item.href,
                'promoCode',
                pCode
              )
              item.href = itemHrefTwo
            }
          }
        })
      }
    }
    const pageLocation = location.search && location.search.length
    if (pageLocation) {
      const pageLoc = location.search
      const promos = pageLoc.split('?')[1]
      if (promos) {
        const promoCode = promos.split('=')[1] || false
        if (promoCode) {
          // Save data to sessionStorage
          sessionStorage.setItem('clientV2Journey', true)
          // Set Promocode to the Credit card page
          if (!isClientAppliedCC()) {
            enableV2LinksForCards()
          }
        }
      } else {
        const clientJourneyV2 = sessionStorage.getItem('clientV2Journey')
        if (JSON.parse(clientJourneyV2)) {
          // Set Promocode to the Credit card page
          if (!isClientAppliedCC()) {
            enableV2LinksForCards()
          }
        }
      }
    } else {
      const clientJourneyV2 = sessionStorage.getItem('clientV2Journey')
      if (JSON.parse(clientJourneyV2)) {
        // Set Promocode to the Credit card page
        if (!isClientAppliedCC()) {
          enableV2LinksForCards()
        }
      }
    }
  }

  const setStorageItem = (pageName, hasButtonClicked) => {
    let visitStorageLoad = AMPPersonalization.getClientStorage()
    if (visitStorageLoad) {
      let visitStorageLoaded = JSON.parse(visitStorageLoad)
      let updatedStorage = visitStorageLoaded.map(item => {
        if (item.pagename === pageName) {
          item.timestamp = Date.now()
          item.hasVisited = true
          item.hasButtonClicked = hasButtonClicked
        }
        return item
      })
      AMPPersonalization.setClientStorage(updatedStorage)
    }
  }

  AMPPersonalization.visitCCPage = pageName => {
    /* Client visited the credit visa signature page but didn't click the Apply now button */
    if (AMPPersonalization.userStatus() === AMPPersonalization.defaultStatus) {
      return false
    }
    setStorageItem(pageName, false)
    const buttons = document.querySelectorAll('.Button.Button--primary')

    if (buttons && buttons.length === 2) {
      ;[...buttons].map(element =>
        element.addEventListener('click', event => {
          const textLowercase =
            event.target &&
            event.target.innerText &&
            event.target.innerText.toLowerCase()
          if (textLowercase && textLowercase.search(/apply/i) != -1) {
            /* Client visited the credit visa signature page and clicked the Apply now button */
            setStorageItem(pageName, true)
          }
        })
      )
    }
  }

  AMPPersonalization.logout = () => {
    if (AMPPersonalization.userStatus() === AMPPersonalization.defaultStatus) {
      return false
    }
  }

  AMPPersonalization.defaultPage = () => {
    return false
  }

  /* Get Client recent visits history from storage */
  AMPPersonalization.getClientStorage = () => {
    if (window.localStorage) {
      const sessionClientStorage = window.localStorage.getItem(
        'clientPageVisitInfo'
      )
      return sessionClientStorage
    }
    return false
  }

  /* Set Client recent visits history in the storage */
  AMPPersonalization.setClientStorage = data => {
    if (window.localStorage && data) {
      window.localStorage.setItem('clientPageVisitInfo', JSON.stringify(data))
    }
  }

  /* Set Client recent visits history in the storage - Default */
  AMPPersonalization.setClientDefaultStorage = (
    data = AMPPersonalization.clientData
  ) => {
    console.log('setDefaultData')
    if (window.localStorage) {
      const sessionClientStorage = window.localStorage.getItem(
        'clientPageVisitInfo'
      )
      const visitStorageLoaded =
        sessionClientStorage && JSON.parse(sessionClientStorage)
      if (
        sessionClientStorage === null ||
        visitStorageLoaded === null ||
        visitStorageLoaded.length < data.length
      ) {
        window.localStorage.setItem('clientPageVisitInfo', JSON.stringify(data))
      }
    }
  }

  /* Methods getting triggered during page initial load */
  AMPPersonalization.registerEvents = () => {
    AMPPersonalization.hostName = window.location.hostname
    AMPPersonalization.pathName = window.location.pathname

    // Check if Client storage is present, if not, create one
    AMPPersonalization.setClientDefaultStorage()

    const currentPageName = AMPPersonalization.findCurrentUrl()
    if (currentPageName) {
      switch (currentPageName) {
        case 'credit-cards':
          AMPPersonalization.visitCCPage('credit-cards')
          AMPPersonalization.creditCardsV2()
          break
        case 'visa-signature':
          AMPPersonalization.visitCCPage('visa-signature')
          break
        case 'premier-visa-signature':
          AMPPersonalization.visitCCPage('premier-visa-signature')
          break
        case 'ameriprise-rewards':
          AMPPersonalization.visitCCPage('ameriprise-rewards')
          break
        case 'logout':
          AMPPersonalization.logout()
          break
        default:
          AMPPersonalization.defaultPage()
      }
    }
  }

  window.addEventListener('load', event => {
    AMPPersonalization.registerEvents()
  })
})(window, document)
